$primary: #FF0000;
$secondary: #222;

#google-translate {
    a {
		background: transparent;
		color: $secondary;
		padding-top: .5rem;
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 1rem;
		font-weight: bold;
		text-transform: uppercase;
		line-height: 1.8rem;
		letter-spacing: 0.2em;
		text-align: center;
		position: relative;
		top: -2px;
        img {
            opacity: 0.5;
            transition: all 0.3s ease;
        }
        &.active {
			color: $primary;
		}
		&:hover{
			box-shadow: 0px 4px 1px -3px $primary;
			text-decoration: none;
		}
	}

}

.gt .skiptranslate {
    display: none !important;
}

body.gt {
    top: 0 !important;
}


// a.flag {
//     text-decoration: none;
// }

// a.flag img {
//     vertical-align: middle;
//     padding: 0;
//     margin: 0;
//     border: 0;
//     display: inline;
//     height: 16px;
//     opacity: 0.8;
// }

// a.flag:hover img {
//     opacity: 1;
// }

// a.flag span {
//     margin-right: 5px;
//     font-size: 15px;
//     vertical-align: middle;
// }

// #goog-gt-tt {
//     display: none !important;
// }

// .goog-te-banner-frame {
//     display: none !important;
// }

// .goog-te-menu-value:hover {
//     text-decoration: none !important;
// }

// body {
//     top: 0 !important;
// }

// #google_translate_element2 {
//     display: none !important;
// }
